import React from 'react';
import './Contact.css';
import '../App.css';

function Contact() {
    return (
        <div className='contact_area' id='contact'>
            
                <div className='contact_title'>

                    <h1 className='text-uppercase'>ne puteți contacta oricând!</h1>

                </div>

                <div className='row'>
                    <div className='col-5 contact_details'>

                        <h2 className='text-uppercase'>datele noastre <br/>de contact sunt:</h2>
                        <hr className="style-three"/>
                        <h3 className='text-uppercase'>Email: <a href='mailto:contact@aptbody.ro'>contact@aptbody.ro</a></h3>
                        <hr className="style-three"/>
                        <h3 className='text-uppercase'>telefon: <a href='callto:+40712345678'>+40 712 345 678</a></h3>
                        <hr className="style-three"/>
                        <h3 className='text-uppercase'>Adresa: str. lorem ipsum nr. 1</h3>

                    </div>
                    <div className='middle col-1'><h1>SAU</h1></div>
                    <div className='container-fluid col-5 contact_form'>
                        <form>
                            <h2 className='text-uppercase'>Ne puteți scrie:</h2>

                            <label htmlFor='lastName'>Nume</label>
                            <input type='text' id='lastName' name='Nume' placeholder='Numele de familie'/>

                            <label htmlFor='firstName'>Prenume</label>
                            <input type='text' id='firstName' name='Prenume' placeholder='Prenumele dumneavoastră'/>

                            <label htmlFor='eMail'>eMail</label>
                            <input type='text' id='eMail' name='Email' placeholder='Email-ul dumneavoastră'/>

                            <label htmlFor='mesaj'>Mesaj</label>
                            <textarea type='text' id='mesaj' name='Mesaj' placeholder='Mesajul dumneavoastră'/>

                            <input type='submit' value='Trimite'/>
                        </form>
                    </div>
                </div>
        </div>
        
    );
}

export default Contact;
