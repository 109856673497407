import React, {useEffect} from 'react';
import './Despre.css';
import '../App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Despre() {
    useEffect(() =>{
        Aos.init({duration:1500});
    }, []);
    return (
        <div className='despre row' id='despre'>

            <div className='col-6 image-area' data-aos='slide-right'>
                <img src= {process.env.PUBLIC_URL + './images/despre.jpg'} alt="APT Mind & Body"/> 
            </div>

            <div className='col-6 text-area' data-aos='zoom-in-left'>
                <h2>Ce este APT?</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dapibus urna non libero dictum consectetur. <br/>Ut ultrices, purus in pretium convallis, risus massa ultrices urna, quis tristique orci erat nec ex. Duis auctor mollis libero, sed dapibus odio euismod sed. <br/>Integer eget quam eu nunc porttitor porta convallis sed nibh. Phasellus dui nisl, sollicitudin sit amet tellus vel, suscipit molestie ligula. <br/>Praesent lobortis molestie volutpat. Ut imperdiet sollicitudin neque et vestibulum. Etiam sit amet orci a metus lobortis porta ut nec diam. In feugiat nisl velit, vel tempor erat aliquam ut.</p>
            </div>

        </div>
    );
}

export default Despre;
