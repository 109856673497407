import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Navbar.css';


function Navbar() {

    const [click, setClick] = useState(false);
    
        
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    return (
         <>
            <nav className="navbar" id="navbar">
                <div className="navbar-container">
                    <Link to="hero" className="navbar-logo" onClick={closeMobileMenu}>
                        <img alt="APT - Personal Trainer" src="./images/logow.png" width="200px"/>
                    </Link>
                    <div className='empty-space'></div>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={ click ? 'fas fa-times' : 'fas fa-bars' } />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='hero' 
                                className='nav-links' 
                                onClick={closeMobileMenu} 
                                activeClass='active' 
                                spy={true} 
                                smooth={'easeInOutQuint'} 
                                duration={100} 
                                delay={0}
                            >
                                Acasa
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link 
                                to='despre' 
                                className='nav-links' 
                                onClick={closeMobileMenu} 
                                activeClass='active' 
                                spy={true} 
                                smooth={'easeInOutQuint'} 
                                duration={100} 
                                delay={0}
                            >
                                Despre
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link 
                                to='preturi' 
                                className='nav-links' 
                                onClick={closeMobileMenu} 
                                activeClass='active' 
                                spy={true} 
                                smooth={'easeInOutQuint'} 
                                duration={100} 
                                delay={0}
                            >
                                Prețuri
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link 
                                to='contact' 
                                className='nav-links' 
                                onClick={closeMobileMenu} 
                                activeClass='active' 
                                spy={true} 
                                smooth={'easeInOutQuint'} 
                                duration={100} 
                                delay={0}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>              
                </div>
            </nav>
        </>
     );
    
}

export default Navbar;
