import React from 'react';
import { Link } from 'react-scroll';


function CardItem(props) {
    return (
        <>
            <li className={props.class} style={props.style}>

                <Link className='cards__item__link' to={props.path} style={{backgroundImage: props.src}}>                    
                    <div className='cards__title'>
                            <h1 className={props.color}>{props.title}</h1>
                    </div>
                    <div className='cards__price'>
                            <h1><sup>lunar</sup><span className="price">{props.price}</span><small>lei</small></h1>
                    </div>

                    <div className='cards__item__info'>
                            <h5 className='cards__item__text'>{props.description}</h5>
                    </div>
                </Link>
                
                
            </li>
        </>
    );
}

export default CardItem;
