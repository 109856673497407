import React, {useEffect} from 'react';
import { Button } from './Button';
import './Hero.css';
import '../App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Hero() {
    useEffect(() =>{
        Aos.init({duration:1500});
    }, []);
    return (
        
        <div className='hero-container' id='hero'>
            <video src='/videos/video-1.mp4' autoPlay loop muted/>
            <h1 data-aos='fade-right' data-aos-duration='3500' data-aos-delay='300'>obține <br></br>rezultatele <br></br>dorite</h1>
            
            <p>toată lumea este <span>APT</span>ă</p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    Alătură-te acum!
                </Button>
            </div>
        </div>
    );
}

export default Hero;
