import React from 'react';
import {Button} from './Button';
import { Link } from 'react-router-dom';
import './Footer.css'

function Footer() {
    return (
        <div className='footer-container'>

            <section className='row footer-subscription'>

                <div className='col-5 footer-subscription-form'>
                    
                    <p className='footer-subscription-heading'>
                    Abonează-te și vei primi cele mai bune oferte pentru fericire!
                    </p>

                    <p className='footer-subscription-text'>
                    Te poți dezabona oricând.
                    </p>

                </div>

                <div className='col-5 input-areas'>

                    <form>
                        <input
                        className='footer-input'
                        name='email'
                        type='email'
                        placeholder='Email-ul tau'
                        />
                        <Button buttonStyle='btn--outline'>Abonează-te</Button>
                    </form>

                </div>

            </section>
            
            <div className='footer-links'>

                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>About Us</h2>
                        <Link to='despre'>Despre APT</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Terms of Service</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>Contact Us</h2>
                        <Link to='/'>Contact</Link>
                        <Link to='/'>Suport</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>Videos</h2>
                        <Link to='/'>Ambassadors</Link>
                        <Link to='/'>Agency</Link>
                        <Link to='/'>Influencer</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='/'>Instagram</Link>
                        <Link to='/'>Facebook</Link>
                    </div>
                </div>
            </div>
            
            <section className='social-media'>

                <div className='social-media-wrap'>

                    <div className='footer-logo'>
                        <a href='https://www.facebook.com/pikawebit'>
                            <img src= {process.env.PUBLIC_URL + './images/PeakWeb_logo_icon.png'} width='50px' alt='PeakWeb'/> 
                            <small className='website-rights'> PeakWeb © 2021</small>
                        </a>
                    </div>
                    
                    

                    <div className='social-icons'>
                        <a
                        className='social-icon-link facebook'
                        href='https://www.facebook.com/pikawebit'
                        >
                        <i className='fab fa-facebook' />
                        </a>
                        <a
                        className='social-icon-link instagram'
                        href='https://www.instagram.com/pikawebit/'
                        >
                        <i className='fab fa-instagram' />
                        </a>
                        <a
                        className='social-icon-link web'
                        href='https://pikaweb.ro/'>
                            <i className='fas fa-globe'/>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
