import React from 'react';
import '../../App.css';
import Hero from '../Hero'
import Preturi from '../Preturi';
import Despre from '../Despre';
import Contact from '../Contact';
import Footer from '../Footer';
import {Parallax} from 'react-parallax';

const parallax1 = "./images/parallax1.jpg"
const parallax2 = "./images/parallax2.jpg"
const parallax3 = "./images/parallax3.jpg"

function Home () {
   return (
    <>
        <Hero />
        <Parallax 
            blur={5}
            bgImage = {parallax1}
            bgImageAlt = 'APT - Personal Trainer'
            strength = {-500}
        >
            <div style={{height: '600px'}}/>
        </Parallax>
        <Despre/>
        <Parallax 
            blur={5}
            bgImage = {parallax2}
            bgImageAlt = 'APT - Personal Trainer'
            strength = {-500}
        >
            <div style={{height: '600px'}}/>
        </Parallax>
        <Preturi/>
        <Parallax 
            blur={5}
            bgImage = {parallax3}
            bgImageAlt = 'APT - Personal Trainer'
            strength = {-500}
        >
            <div style={{height: '600px'}}/>
        </Parallax>
        <Contact/>
        <Footer/>
    </>
   );
}

export default Home;