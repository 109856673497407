import React from 'react';
import '../App.css';
import CardItem from './CardItem';
import './Preturi.css';

function Preturi() {
    return (
        <>
        {/* style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/bg2.jpg'})`
        }} */}
        <div className='preturi' id='preturi' >
            
                <h1>Pachetele noastre!</h1>
                <div className='cards' >
                    
                    <div className='cards__container'>
                        <div className='cards__wrapper'>
                            <ul className='cards__items'>
                                <CardItem
                                    title='pachetul bronze'
                                    price = ' 100 '
                                    description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    path='contact'
                                    color='bronze'
                                    class = 'cards__item left__card'
                                    src = {`url(${process.env.PUBLIC_URL + '/images/wavy-bg-bronze.png'})`}
                                />
                                <CardItem
                                    title='pachetul gold'
                                    price = ' 200 '
                                    description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    path='contact'
                                    color='gold'
                                    class = 'cards__item middle__card'
                                    src = {`url(${process.env.PUBLIC_URL + '/images/wavy-bg-red.png'})`}
                                />
                                <CardItem
                                    title='pachetul silver'
                                    price = ' 150 '
                                    description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    path='contact'
                                    color='silver'
                                    class = 'cards__item right__card'
                                    src = {`url(${process.env.PUBLIC_URL + '/images/wavy-bg-silver.png'})`}
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            
        </div>
        
        </>
        
    );
}

export default Preturi;
